import { PageHeader } from "components";
import type { NextPage } from "next";
import {
  DeliverySection,
  HeroSection,
  JetsonOneSection,
  LatestNewsSection,
  NewsletterSection,
} from "sections";
import {
  IArticleV3Fields,
  IDeliverySectionFields,
  IHeroSectionFields,
  IJetsonOnePerkListFields,
  IJetsonOneSectionFields,
  INews,
  INewsletterSectionFields,
  ITeamV3Fields,
} from "types";
import { fetchPerks, getContentfulEntries } from "utils/contentful-api";

const Home: NextPage<{
  news: INews[];
  hero: IHeroSectionFields[];
  perksList: IJetsonOnePerkListFields[];
  jetsonOne: IJetsonOneSectionFields[];
  delivery: IDeliverySectionFields[];
  newsletter: INewsletterSectionFields[];
}> = ({ news, hero, perksList, jetsonOne, delivery, newsletter }) => {
  return (
    <div>
      <PageHeader title="Home" />
      <HeroSection heroSection={hero} />
      <JetsonOneSection jetsonOne={jetsonOne} perksList={perksList} />
      <DeliverySection deliverySection={delivery} />
      <LatestNewsSection news={news} />
      <NewsletterSection newsletterSection={newsletter} />
    </div>
  );
};

export default Home;

export async function getStaticProps() {
  const news = await getContentfulEntries<IArticleV3Fields>(
    "articleV3",
    "-sys.createdAt",
    10
  );

  const team = await getContentfulEntries<ITeamV3Fields>(
    "teamV3",
    "sys.updatedAt"
  );

  const hero = await getContentfulEntries<IHeroSectionFields>("heroSection");

  const { perksList } = await fetchPerks();

  const jetsonOne = await getContentfulEntries<IJetsonOneSectionFields>(
    "jetsonOneSection"
  );

  const delivery = await getContentfulEntries<IDeliverySectionFields>(
    "deliverySection"
  );

  const newsletter = await getContentfulEntries<INewsletterSectionFields>(
    "newsletterSection"
  );

  return {
    props: {
      news,
      team,
      hero,
      perksList,
      jetsonOne,
      delivery,
      newsletter,
    },
  };
}
